import React from "react";
import '../../../App.css';
import CVSection from './CVSection'
import Footer from '../../Footer';

export default function Cv() {
    return (
        <>
            <CVSection />
            <Footer />
        </>
    );
}