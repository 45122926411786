import React from 'react'
import '../../../App.css'
import ProjectSection from './ProjectSection';
import Footer from '../../Footer';

export default function Projects (){
    return (
    <>
      <ProjectSection/>
      <Footer/>
    </>
    );
}
