import React from "react";
import '../../../App.css';
import Footer from '../../Footer';
import AboutSection from './AboutSection';

export default function About() {
    return (
        <>
            <AboutSection />
            <Footer />
        </>
    );
}